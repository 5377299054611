const NAME = 'bulma-extension-helpers';
const VERSION = '1.0';

function getAll(selector) {
    return Array.prototype.slice.call(document.querySelectorAll(selector), 0);
}

const randomId = () => {
    return Math.random().toString(36).substring(2);
};

module.exports = {
    getAll, randomId
};