const NAME = 'bulma-navbars';
const VERSION = '1.0';

const helpers = require('./helpers');

let initNavBars = () => {
    console.log(NAME, 'initNavBars');

    // Get all "navbar-burger" elements
    const $navbarBurgers = helpers.getAll('.navbar-burger');

    // Check if there are any navbar burgers
    if ($navbarBurgers.length === 0) {
        return;
    }

    global.toggleNavBar = (el) => {
        // Get the target from the "data-target" attribute
        const target = el.dataset.target;
        const $target = document.getElementById(target);

        // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
        el.classList.toggle('is-active');
        $target.classList.toggle('is-active');
    };

    global.hideNavBar = (el) => {
        // Get the target from the "data-target" attribute
        const target = el.dataset.target;
        const $target = document.getElementById(target);

        // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
        el.classList.remove('is-active');
        $target.classList.remove('is-active');
    };

    global.showNavBar = (el) => {
        // Get the target from the "data-target" attribute
        const target = el.dataset.target;
        const $target = document.getElementById(target);

        // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
        el.classList.add('is-active');
        $target.classList.add('is-active');
    };

    global.toggleNavBars = () => {
        $navbarBurgers.forEach( el => {
            toggleNavBar(el);
        });
    };

    global.hideNavBars = () => {
        $navbarBurgers.forEach( el => {
            hideNavBar(el);
        });
    };

    global.showNavBars = () => {
        $navbarBurgers.forEach( el => {
            showNavBar(el);
        });
    };

    // Add a click event on each of them
    $navbarBurgers.forEach( el => {
        el.addEventListener('click', () => {
            toggleNavBar(el);
        });
    });
};

module.exports = {
    initNavBars
}