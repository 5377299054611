const defaults = {
    imageLoadedClass: 'lazy-image--handled',
    imageClass: 'is-lazy-image',
    backgroundLoadedClass: 'lazy-background--handled',
    backgroundClass: 'is-lazy-background',
    // If the image gets within 50px in the Y axis, start the download.
    rootMargin: '50px 0px',
    threshold: 0.01
};

let config,
    images,
    backgrounds,
    entriesCount,
    observer;

/**
 * Fetches the image for the given URL
 * @param {string} url
 */
function fetchImage(url) {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.src = url;
        image.onload = resolve;
        image.onerror = reject;
    });
}

/**
 * Preloads the image
 * @param {object} image
 */
function preloadImage(image) {
    const src = image.dataset.src;
    if (!src) {
        return;
    }

    return fetchImage(src).then(() => { applyImage(image, src); });
}
function preloadBackground(background) {
    const src = background.dataset.src;
    if (!src) {
        return;
    }

    return fetchImage(src).then(() => { applyBackground(background, src); });
}

/**
 * Load all of the images immediately
 * @param {NodeListOf<Element>} images
 */
function loadImagesImmediately(images) {
    // foreach() is not supported in IE
    for (let i = 0; i < images.length; i++) {
        let image = images[i];
        preloadImage(image);
    }
}

function loadBackgroundsImmediately(backgrounds) {
    // foreach() is not supported in IE
    for (let i = 0; i < backgrounds.length; i++) {
        let background = backgrounds[i];
        preloadBackground(background);
    }
}

/**
 * Disconnect the observer
 */
function disconnect() {
    if (!observer) {
        return;
    }

    observer.disconnect();
}

/**
 * On intersection
 * @param {array} entries
 */
function onIntersection(entries) {
    // console.log('onIntersection', entries);

    // console.log('onIntersection', 'loop');

    // Loop through the entries
    for (let i = 0; i < entries.length; i++) {
        let entry = entries[i];
        // console.log('onIntersection', entry);
        // Are we in viewport?
        if (entry.intersectionRatio > 0) {
            entriesCount--;

            // Stop watching
            observer.unobserve(entry.target);

            if (entry.target.classList.contains(config.imageClass)) {
                // Load the image
                // console.log('onIntersection', 'image');
                preloadImage(entry.target);
            } else if (entry.target.classList.contains(config.backgroundClass)) {
                // Load the background
                // console.log('onIntersection', 'background');
                preloadBackground(entry.target);
            }
        }
    }

    // Disconnect if we've already loaded all of the images
    if (entriesCount === 0) {
        // console.log('onIntersection', 'disconnect');
        disconnect();
    }
}

/**
 * Apply the image
 * @param {object} img
 * @param {string} src
 */
function applyImage(img, src) {
    // Prevent this from being lazy loaded a second time.
    img.classList.add(config.imageLoadedClass);
    img.src = src;
}
function applyBackground(bg, src) {
    // Prevent this from being lazy loaded a second time.
    bg.classList.add(config.backgroundLoadedClass);
    bg.style.backgroundImage = `url('${src}')`;
}


let LazyLoad = {

    init: (options) => {
        config = {...defaults, ...options};

        images = document.querySelectorAll('.' + config.imageClass);
        backgrounds = document.querySelectorAll('.' + config.backgroundClass);
        entriesCount = images.length + backgrounds.length;

        // console.log('lazy-images', images, images.length);
        // console.log('lazy-backgrounds', backgrounds, backgrounds.length);
        // console.log('lazy-entries', entriesCount);

        // If we don't have support for intersection observer, loads the images immediately
        if (!('IntersectionObserver' in window)) {
            loadImagesImmediately(images);
            loadBackgroundsImmediately(backgrounds);
        } else {
            // It is supported, load the images
            observer = new IntersectionObserver(onIntersection, config);

            // foreach() is not supported in IE
            for (let i = 0; i < images.length; i++) {
                let image = images[i];
                if (image.classList.contains(config.imageLoadedClass)) {
                    continue;
                }

                // console.log('lazy-image', image);
                observer.observe(image);
            }

            // foreach() is not supported in IE
            for (let i = 0; i < backgrounds.length; i++) {
                let background = backgrounds[i];
                if (background.classList.contains(config.backgroundLoadedClass)) {
                    continue;
                }

                // console.log('lazy-background', background);
                observer.observe(background);
            }

            // console.log('observer', observer);
        }
    }
};

export default LazyLoad;