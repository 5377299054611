const NAME = 'bulma-extension';
const VERSION = '1.0';

module.exports = {
    initDropdowns: require('./dropdowns').initDropdowns,
    initExpandableContent: require('./expandable-content').initExpandableContent,
    initModals: require('./modals').initModals,
    initNavBars: require('./navbars').initNavBars,
    initTabs: require('./tabs').initTabs,
    initSliders: require('./sliders').initSliders,
}