const NAME = 'bulma-modals';
const VERSION = '1.0';

const helpers = require('./helpers');

const initModals = () => {
    console.log(NAME, 'initModals');

    // Auto Modals

    var $imageModals = helpers.getAll('[data-modal="image"]');

    if ($imageModals.length > 0) {
        $imageModals.forEach($el => {
            const id = 'modal_' + helpers.randomId();
            const src = $el.dataset.src;
            $el.dataset.target = id;
            $el.classList.add("image", "modal-button");
            document.body.append(generateImageModal(id, src));
        })
    }


    // Modals

    var rootEl = document.documentElement;
    var $modals = helpers.getAll('.modal');
    var $modalButtons = helpers.getAll('.modal-button');
    var $modalCloses = helpers.getAll('.modal-background, .modal-close, .modal-card-head .delete, .modal-card-foot .button');

    if ($modalButtons.length > 0) {
        $modalButtons.forEach(function ($el) {
            $el.addEventListener('click', function () {
                var target = $el.dataset.target;
                openModal(target);
            });
        });
    }

    if ($modalCloses.length > 0) {
        $modalCloses.forEach(function ($el) {
            $el.addEventListener('click', function () {
                closeModals();
            });
        });
    }

    function openModal(target) {
        var $target = document.getElementById(target);
        rootEl.classList.add('is-clipped');
        $target.classList.add('is-active');
    }

    function closeModals() {
        rootEl.classList.remove('is-clipped');
        $modals.forEach(function ($el) {
            $el.classList.remove('is-active');
        });
    }

    document.addEventListener('keydown', function (event) {
        var e = event || window.event;
        if (e.keyCode === 27) {
            closeModals();
            // closeDropdowns();
        }
    });
};

/*
.modal
  .modal-background
  .modal-content
    p.image.is-4by3
      img(src='https://bulma.io/images/placeholders/1280x960.png', alt='')
  button.modal-close.is-large(aria-label='close')
 */
function generateImageModal(id, src) {
    var modal = document.createElement("div");
    modal.id = id;
    modal.classList.add("modal", "modal-auto");
    var modalBackground = document.createElement("div");
    modalBackground.className = "modal-background";
    modal.append(modalBackground);

    var modalContent = document.createElement("div");
    modalContent.className = "modal-content";
    var modalFigure = document.createElement("div");
    modalFigure.className = "image";
    // modalFigure.classList.add('image', 'cover-contain');
    // modalFigure.style.backgroundImage = `url(${src})`;

    var modalImage = document.createElement("img");
    modalImage.src = src;
    modalFigure.append(modalImage);

    modalContent.append(modalFigure);
    modal.append(modalContent);

    var modalButton = document.createElement("button");
    modalButton.classList.add("modal-close", "is-large");
    modalButton.setAttribute('aria-label', 'close');
    modal.append(modalButton);

    return modal;
}

function updateImageModal(id, src) {
    let modal = document.getElementById(id);
    // let modalImage = modal.querySelector(`.image`);
    // modalImage.style.backgroundImage = `url(${src})`;
    let modalImage = modal.querySelector(`.image img`);
    modalImage.src = src;
}

function showImageModal(id, src) {
    if (!is.nue(src)) {
        updateImageModal(id, src)
    }

    document.documentElement.classList.add('is-clipped');
    document.getElementById(id).classList.add('is-active');
}

function closeImageModal(id) {
    document.documentElement.classList.remove('is-clipped');
    document.getElementById(id).classList.remove('is-active');
}

window = window || global;

window.generateImageModal = generateImageModal;
window.updateImageModal = updateImageModal;
window.showImageModal = showImageModal;
window.closeImageModal = closeImageModal;

module.exports = {
    initModals
};