
// modules
// require('../../_shared/shared');
global.roundToDecimal = require('../../modules/helpers/numbers-helper').roundToDecimal;

let $ = {
    is: {
        nue: (object) => {
            if (object === undefined) return true;
            if (object === null) return true;

            if (typeof object === 'string') {
                let text = object.replace(/\s+/gi, '');
                if (text.length === 0) return true;
            }

            // Object.keys(new Date()).length is 0
            // so we need to make the check specific for Date objects
            if (object instanceof Date) {
                return isNaN(object.getTime());
            }

            if (typeof object === 'object') {
                return Object.keys(object).length === 0;
            }

            return false;
        },
    },
    cryptoUtils: {
        randomStupidString: (length) => {
            let result = '';
            let characters = 'ADFGHJKLMNPRSTWXYZ2345679';
            let charactersLength = characters.length;
            for (let i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result;
        },
    },
};
Object.assign($, {
    softClone: function (obj) {
        if ($.is.nue(obj)) return undefined;
        return JSON.parse(JSON.stringify(obj));
    },

    inlineAssetValue: (item, titleProperty) => {
        if ($.is.nue(item)) {
            return undefined;
        }
        item = item instanceof Parse.Object ? $.softClone(item) : item;
        let id = item.id || item.objectId;
        let title = item[titleProperty] || item.title || item.name;
        let {protocol, mac} = item;
        return `${protocol} :: ${mac} :: ${title} / id:${id}`;
    },

    inlineAssetId: (string, idOnly = true) => {
        if ($.is.nue(string) || typeof string !== 'string') {
            return undefined;
        }
        let mSplit = string.split(/.+\s\/\sid:([A-Za-z0-9]{10})/i);
        let id, title;

        if (mSplit.length === 3) {
            title = mSplit[0];
            id = mSplit[1];
        } else if (mSplit.length === 1 && mSplit[0].length === 10) {
            id = mSplit[0];
        }

        if (!$.is.nue(id)) {
            if (idOnly) {
                return id;
            } else {
                return {title, id};
            }
        }
        return undefined;
    },
});
global.$ = $;

// properties
const VERSION = '1.0.0';
const NAME = 'app-scripts';

global.LazyLoad = require('../../frontend/src/helpers/lazy-load').default;

const bulmaExtension = require('../../frontend/src/bulma-extension');

window.addEventListener("DOMContentLoaded", init);
// window.onload = function() {
//     init();
// };

function init() {
    console.log(NAME, 'init');

    if (parseConfig) {
        Parse.initialize(parseConfig.applicationId);
        Parse.serverURL = parseConfig.serverURL;
    }

    bulmaExtension.initNavBars();

    bulmaExtension.initModals();
    bulmaExtension.initDropdowns();
    bulmaExtension.initExpandableContent();
    bulmaExtension.initTabs();
    bulmaExtension.initSliders();

    initSortable();
    LazyLoad.init();
}

function initSortable() {
    document.querySelectorAll('[sortable]').forEach(element => {
        Sortable.create(element);
    });
}