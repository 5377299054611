const NAME = 'bulma-expandable-content';
const VERSION = '1.0';

const helpers = require('./helpers');

const initExpandableContent = () => {
    console.log(NAME, 'initExpandableContent');

    // ExpandableContent

    var $expandableToggles = helpers.getAll(`.expandable-toggle`);

    if ($expandableToggles.length > 0) {
        $expandableToggles.forEach(function ($el) {
            $el.addEventListener('click', function (event) {
                event.stopPropagation();
                $el.classList.toggle('is-collapsed');
                $el.classList.toggle('is-extended');

                let icon = $el.querySelector('.material-icons i');
                if (icon) {
                    // icon.classList.toggle('expand_more');
                    // icon.classList.toggle('expand_less');
                    icon.classList.toggle('unfold_more');
                    icon.classList.toggle('unfold_less');
                }

                let expandableContent = document.querySelector($el.getAttribute('data-content'));
                if (expandableContent) {
                    expandableContent.classList.toggle('is-collapsed');
                    expandableContent.classList.toggle('is-extended');
                }

                let text = $el.querySelector('.text');
                if (text) {
                    let textLess = $el.getAttribute('data-text-less');
                    let textMore = $el.getAttribute('data-text-more');
                    text.innerHTML = $el.classList.contains('is-extended') ? textLess : textMore;
                }
            });
        });
    }
};

module.exports = {
    initExpandableContent
}