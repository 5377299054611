const NAME = 'bulma-sliders';
const VERSION = '1.0';

const helpers = require('./helpers');

let initSliders = () => {
    console.log(NAME, 'initSliders');

    // Get all "navbar-burger" elements
    const $sliders = helpers.getAll('.slider');

    // Check if there are any navbar burgers
    if ($sliders.length === 0) {
        return;
    }

    // Add a click event on each of them
    $sliders.forEach( slider => {
        if (slider.classList.contains('is-js-disabled')
            || slider.classList.contains('ignore-js')
            || slider.classList.contains('js-ignore')) {
            return;
        }

        slider.addEventListener('input', sliderChanged);
    });
};

function sliderChanged(event) {
    let slider = event.target;
    let sliderValueOutputElement;

    let unit = slider.getAttribute('data-value-unit');
    if (!unit) {
        unit = '%';
    }

    if (!sliderValueOutputElement) {
        let valueOutputQuery = slider.getAttribute('data-value-output');
        if (valueOutputQuery) {
            sliderValueOutputElement = document.querySelector(valueOutputQuery);
        }
    }

    if (sliderValueOutputElement) {
        sliderValueOutputElement.innerText = `${slider.value}${unit}`;
    }
}

module.exports = {
    initSliders
}