const NAME = 'bulma-tabs';
const VERSION = '1.0';

const helpers = require('./helpers');

let initTabs = () => {
    console.log(NAME, 'initTabs');

    // Get all "navbar-burger" elements
    const $tabs = helpers.getAll('.tabs');

    // Check if there are any navbar burgers
    if ($tabs.length === 0) {
        return;
    }

    // Add a click event on each of them
    $tabs.forEach( tab => {
        if (tab.classList.contains('is-js-disabled')
            || tab.classList.contains('ignore-js')
            || tab.classList.contains('js-ignore')) {
            return;
        }
        tab.querySelectorAll('li').forEach(li => {
            li.addEventListener('click', (e) => {
                let clickedLi = e.currentTarget;
                e.stopPropagation();
                e.preventDefault();
                // console.log(e, clickedLi);

                let clickedIndex = 0;

                // reset active and hide content for all elements
                clickedLi.parentNode.querySelectorAll('li').forEach((element, index) => {
                    // console.log('li', index, element == clickedLi);
                    if (element == clickedLi) {
                        clickedIndex = index;
                    }
                    element.classList.remove('is-active');
                    let content = document.querySelector(element.getAttribute('data-content'));
                    if (content) {
                        content.classList.add('is-hidden');
                        content.classList.remove('is-visible');
                    }
                });
                // set clicked as active
                clickedLi.classList.add('is-active');
                let content = document.querySelector(clickedLi.getAttribute('data-content'));
                if (content) {
                    content.classList.remove('is-hidden');
                    content.classList.add('is-visible');
                }

                let radio = clickedLi.querySelector('input[type="radio"]');
                // console.log('radio', radio);
                if (radio) {
                    let radios = clickedLi.parentNode.querySelectorAll(`input[type="radio"]`);
                    // console.log(radios);
                    radios.forEach(r => r.removeAttribute('checked'));
                    radio.setAttribute('checked', 'checked');
                }

                let clickedTab = clickedLi.parentNode.classList.contains('tabs') ? clickedLi.parentNode : clickedLi.parentNode.parentNode;
                let sync = clickedTab.getAttribute('data-sync');
                if (sync) {
                    $tabs.forEach(tab => {
                        if (tab === clickedTab || tab.getAttribute('data-sync') !== sync) {
                            return;
                        }
                        // reset active and hide content for all elements
                        tab.querySelectorAll('li').forEach((element, index) => {
                            // console.log('sync li', index, index == clickedIndex);
                            if (index === clickedIndex) {
                                element.classList.add('is-active');
                            } else {
                                element.classList.remove('is-active');
                            }
                            let content = document.querySelector(element.getAttribute('data-content'));
                            if (content) {
                                if (index === clickedIndex) {
                                    content.classList.remove('is-hidden');
                                    content.classList.add('is-visible');
                                } else {
                                    content.classList.add('is-hidden');
                                    content.classList.remove('is-visible');
                                }
                            }
                            let radio = element.querySelector('input[type="radio"]');
                            if (radio) {
                                if (index === clickedIndex) {
                                    radio.setAttribute('checked', 'checked');
                                } else {
                                    radio.removeAttribute('checked');
                                }
                            }
                        });
                    })
                }

                let event = new Event('change');
                clickedTab.dispatchEvent(event);

            }, false); // false to disable capturing
        });
    });
};

module.exports = {
    initTabs
}