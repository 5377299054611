// node modules


// modules


// properties
const VERSION = '1.0.0';
const NAME = 'numbers-helper';



const UNIT = {
    DEFAULT: 'default',
    KILO: 'kilo',
    MEGA: 'mega',
    GIGA: 'giga',
    TERA: 'tera',
    PETA: 'peta',
    EXA: 'exa',
    ZETTA: 'zetta',
    YOTTA: 'yotta',
};
const UNIT_POWER = {};
UNIT_POWER[UNIT.DEFAULT] = 0;
UNIT_POWER[UNIT.KILO] = 1;
UNIT_POWER[UNIT.MEGA] = 2;
UNIT_POWER[UNIT.GIGA] = 3;
UNIT_POWER[UNIT.TERA] = 4;
UNIT_POWER[UNIT.PETA] = 5;
UNIT_POWER[UNIT.EXA] = 6;
UNIT_POWER[UNIT.ZETTA] = 7;
UNIT_POWER[UNIT.YOTTA] = 8;

// methods


// module exports
module.exports = {
    VERSION: VERSION,
    NAME: NAME,

    roundToDecimal: function(number, precision) {
        precision = precision || 2;
        let factor = Math.pow(10, precision);
        let tempNumber = number * factor;
        let roundedTempNumber = Math.round(tempNumber);
        return roundedTempNumber / factor;
    },

    formatToMoney: function (number, currencyCode, precision) {
        currencyCode = currencyCode || 'EUR';
        return this.roundToDecimal(number, precision) + ' ' + currencyCode;
    },

    countDecimals: countDecimals,

    randomArbitrary: getRandomArbitrary,
    randomInt: getRandomInt,
    randomNumber: getRandomNumber,

    formatProcessingPower(currencyCode, amount, input, output, decimals) {
        input = input || UNIT.DEFAULT;
        output = output || UNIT.DEFAULT;

        if (output !== input)
            amount = amount * Math.pow(1000, UNIT_POWER[input]) / Math.pow(1000, UNIT_POWER[output]);

        decimals = decimals || 0;
        if (decimals > 0) {
            amount = this.roundToDecimal(amount, decimals);
        }

        let unit = '';

        switch (currencyCode) {
            case 'ETH':
            case 'ETC':
            case 'LTC':
                unit = output !== UNIT.DEFAULT ? output.charAt(0) + 'H/s' : 'H/s';
                break;
            case 'BTC':
                unit = output !== UNIT.DEFAULT ? output.charAt(0) + 'H/s' : 'H/s';
                break;
            case 'ZEC':
                unit = output !== UNIT.DEFAULT ? output.charAt(0) + 'H/s' : 'H/s';
                break;
            case 'DASH':
                unit = output !== UNIT.DEFAULT ? output.charAt(0) + 'H/s' : 'H/s';
                break;
            default:
                break;
        }

        return {
            amount: amount,
            unit: unit,
            formatted: amount + ' ' + unit,
        }
    },

    UNIT: UNIT,
    UNIT_POWER: UNIT_POWER,
};

/**
 * Returns the number of decimals
 * @param number
 * @return {number}
 */
function countDecimals(number) {
    if ((number % 1) !== 0)
        return number.toString().split(".")[1].length;
    return 0;
}

/**
 * Returns a random number between min (inclusive) and max (exclusive)
 */
function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
}

/**
 * Returns a random integer between min (inclusive) and max (inclusive)
 * Using Math.round() will give you a non-uniform distribution!
 */
function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

/**
 * Returns a random number taking the decimalPlaces or max decimal places from the given numbers
 * @param min
 * @param max
 * @param decimalPlaces
 * @return {number}
 */
function getRandomNumber(min, max, decimalPlaces) {
    decimalPlaces = decimalPlaces || Math.max(countDecimals(min), countDecimals(max));
    let rand = Math.random() * (max - min) + min;
    let power = Math.pow(10, decimalPlaces);
    return Math.floor(rand * power) / power;
}